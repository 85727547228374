@use "sass:math";
@import "./variables.scss";

// Return null rather than throwing an error if index is outside list range.    
@function nth-value($list, $index) {
  @return if(length($list) >= $index, nth($list, $index), null);
}  

@mixin aspect-ratio($arglist... /*$ratio or $width, $height*/){
    $map : keywords($arglist);
    $height: map-get($map, height) or nth-value($arglist, 2);
    $width:  map-get($map, width)  or nth-value($arglist, 1);
    $ratio:  map-get($map, ratio)  or if($width and $height, math.div($width,$height), nth-value($arglist, 1)) or 1;
    $padding: math.div(1, $ratio) * 100%;
    &::before, &::after { content: ''; display: table; clear: both; width:1px; margin-left:-1px; }
    &::before { float: left; padding-bottom: $padding; }    
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}