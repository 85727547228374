/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/mixins.scss";

//fonts

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: local('Poppins Medium'), local('Poppins-Medium'),
  url('./assets/fonts/Poppins-Medium.woff') format('woff'); 
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  src: local('Poppins Bold'), local('Poppins-Bold'),
  url('./assets/fonts/Poppins-Bold.woff') format('woff'); 
}

html {
	font-weight: 500;
	font-size: 1.2rem;
	color: var(--ion-color-black);
}

#mute {
	z-index: 111;
	position: absolute;
	top: 0.6rem;
    left: 1rem;
}

#gotosite {
	z-index: 111;
	position: absolute;
	top: 1rem;
    left: 5.8rem;
    height: 2.4rem;
}

app-score {
	position: absolute;
	right: 2%;
	bottom: 0;
}

//buttons

ion-button {
	--border-radius: 1.8rem/50%;
	--padding-top: 0.4rem;
	--padding-bottom: 0.4rem;
	--border-width: 2px;
	--border-style: solid;
	--border-color: var(--ion-color-black);
	--box-shadow: 0.4rem 0.4rem 0.9rem 0 rgba(0,0,0,0.5);
	max-width: 100%;
    white-space: normal;
	text-transform: none;
	font-weight: 700;
	font-size: 1.4rem;
    letter-spacing: 0;
	line-height: 1.6rem;
	height: min-content;
	&.big {
		font-size: 1.8rem;
		line-height: 2rem;
		height: 3rem;
	}
	&.ion-activated {
		--box-shadow: none;
	}

	&.button-has-icon-only {
		--padding-start: 0;
		--padding-end: 0;
		--padding-top: 0;
		--padding-bottom: 0;
		--border-radius: 50%;
		height: 3.2rem;
		width: 3.2rem;
		font-size: 0.9rem;
	}
}

//shared

.option {
	padding: 3%;
	display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;

	&:not(.result, .tip) { 
		cursor: grab;
	}

    img, .text, .number{
		border: 2px solid var(--ion-color-black);
	}

	img {
		@include aspect-ratio(1);
		border-radius: 50%;
		height: 75%;
		background: #fff;
	}

	ion-icon {
		position: absolute;
		top: 16%;
	    font-size: 2.5rem;
	    --ionicon-stroke-width: 4rem;
	}

	.text {
		position: absolute;
		width: 94%;
		min-height: 25%;
		font-weight: 700;
		font-size: 0.8rem;
    	line-height: 0.8rem;
		padding: 0.45rem 0.9rem;
		background: var(--ion-color-dark-blue);
		color: #fff;
		overflow:hidden;
		display: flex;
    	align-items: center;
    	justify-content: center;
    	text-align: center;
	}

	&.dragging {
		cursor: grabbing;
		img, .text {
			border: 4px solid #fff;
			box-shadow: 0.4vw 0.4vw 0.9vw 0 rgba(0,0,0,0.5);
		}
	}

	&.wrong {
		img {
			border: 2px solid var(--ion-color-red);
		}
		.text, .number {
			background: var(--ion-color-red);
		}
	}

	&.correct {
		.text, .number {
			background: var(--ion-color-green);
		}
		img {
			border-color: var(--ion-color-black);
		}
	}

	&.result, &.tip {
		img, .text, .number {
			border: 2px solid var(--ion-color-red);
		}
		.text, .number {
			background: var(--ion-color-white);
			color: var(--ion-color-black);
		}
	}

	&.tip .text {
		//now text only
		height: 100%;
	}

	&.hidden {
		visibility: hidden;
	}
}

.number {
	position: absolute;
	left: 4%;
    top: 11%;
	border-radius: 50%;
	border:2px solid var(--ion-color-black);
	background: var(--ion-color-dark-blue);
	color: #fff;
	font-size: 1.8rem;
	font-weight: 700;
	width: 20%;
	padding-top: 20%;
	box-sizing: content-box;
	span {
		position: absolute;
		left: 0;
	    top: 0;
	    width: 100%;
	    height: 100%;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	}
}

//full screen modals
ion-modal {
    --width: 100% !important;
    --height: 100% !important;
}

html, body, { width: 100%; height: 100%; } 

body {
	//try to prevent pull to refresh
	overscroll-behavior-y: contain;
}

// Fixed centred 16:9 content
.ion-page {
  aspect-ratio: 16 / 9;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
}

@supports not (aspect-ratio: 16 / 9) {
  .ion-page::before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }

  .ion-page::after {
    display: block;
    content: "";
    clear: both;
  }
}

/*
app-root {
	flex: none;
	width: 100vw; 
    height: 56.25vw; // height:width ratio = 9/16 = .5625  
    max-height: 100vh;
    max-width: 177.78vh; // 16/9 = 1.778 
    position: relative;
}
*/

.ion-page {
	padding-left: 5%;
  	padding-right: 5%;
  	background: var(--ion-color-light-blue) 0 0/15px 15px url("assets/img/bg.svg");
  	user-select: none;
}


//Consistent page layout
ion-grid {
	width: 100%;
	height: 100%;
  	display: flex;
  	align-items: center;
}

ion-row {
	width: 100%;
	height: 88%;
}

ion-col {
	max-height: 100%
}

ion-col.character {
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &.l {
    	align-items: flex-start;
    }
    &.r {
    	align-items: flex-end;
    }
    &.c {
    	align-items: center;
    }
    &.two {
    	flex-direction: row;
    	align-items: flex-end;
    	justify-content: center;
    }
}

//didn;t seem to be ionic class to achieve this
ion-col.vc {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

//anticipate landscape, especially on mobile

@include respond-below(xl) {
	html {
		font-size: 14px;
	}
}

@include respond-below(lg) {
	html {
		font-size: 14px;
	}
	ion-button {
		&.big {
		    font-size: 1.6rem;
		    line-height: 1.8rem;
		    height: 2.8rem;
		}
	}
	.option {
		.text {
			padding: 0.4rem;
		}
	}
}


@include respond-below(md) {
	html {
		font-size: 12px;
	}
	ion-button {
		font-size: 1.2rem;
	}
	.option {
		ion-icon {
			position: absolute;
			top: 0;
			font-size: 2rem;
		}
		img {
			height: 80%;
		}
		.text {
			height: 44%;
			min-height: 44%;
		}
	}

	.number {
		top: 0;
		width: 26%;
		padding-top: 26%;
	}
}

@include respond-below(sm) {
	ion-button {
		font-size: 1.3rem;
	}
	.option {
		ion-icon {
			top: 12%;
			font-size: 3.2rem;
		}
		.text {
			height: 50%;
			min-height: 50%;
			font-size: 0.75rem;
    		line-height: 0.8rem;
		}
	}
}

#overlay {
	display: none;
}

@media (orientation: portrait) {
	.plt-mobile #overlay {
		background: rgba(0,0,0,0.7);
		color: var(--ion-color-white);
		z-index: 999;
		width: 100%;
		height: 100%;
		position: absolute;
		top:0;
		left:0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		p {
			font-weight: 700;
			font-size: 1.4rem;
		}
		ion-icon {
			font-size: 64px;
		}
	}
}

